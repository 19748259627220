import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { ShareHeaderPageTitleDataService } from '../ShareHeaderDataService/ShareHeaderPageTitleData.service';
import { LoginResponse } from '../../_core/interfaces/login';
import { VVS_PTW_DATA_SHARE } from '../../_core/service/data_share.service';
import { AuthService } from '../../_core/service/auth.service';
import { Location } from '@angular/common';
import { VVS_VOIS_SSO_LOGOUT } from '../../_core/_common/constant';
import { EncrDecrService } from '../../_core/service/encr-decr.service';
interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-admin-header',
  standalone: true,
  imports: [MatMenuModule],
  templateUrl: './admin-header.component.html',
  styleUrl: './admin-header.component.scss'
})
export class AdminHeaderComponent {


  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  @ViewChild(SidebarComponent) childComponent !: SidebarComponent;
  public VVS_VOIS_USER_DEATILS: LoginResponse = JSON.parse(this.encdrDecdrService.VVS_VOIS_DECRYPT(localStorage.getItem("currentUser")!));
  public VVS_VOIS_SITE_LIST: any[] = [];
  collapsed = true;
  vvs_vois_PageTitle: string = '';
  VVS_VOIS_PTW_USER_NAME: string | undefined;
  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    this.onToggleSideNav.emit({ collapsed: this.collapsed, screenWidth: 1517 });
  }

  constructor(
    private _Datashare: VVS_PTW_DATA_SHARE,
    private router: Router,
    private shareDataService: ShareHeaderPageTitleDataService,
    private authService: AuthService,
    private location: Location,
    private encdrDecdrService: EncrDecrService

  ) {
    this.VVS_VOIS_DECRYPT_NAME()
  }

  ngOnInit(): void {
    this.shareDataService.selectedPageTitle$.subscribe((value: string) => {
      this.vvs_vois_PageTitle = value;
    })
    this.VVS_VOIS_SITE_LIST = this.VVS_VOIS_USER_DEATILS.Response.data.SITES
    this._Datashare.VVS_VOIS_SET_SITE_ID(this.VVS_VOIS_SITE_LIST[0].KEY);

  }

  goBack() {
    this.location.back();
  }
  VVS_VOIS_DECRYPT_NAME() {
    this.VVS_VOIS_PTW_USER_NAME =   this.VVS_VOIS_USER_DEATILS.Response.data.USER.NAME
    return this.VVS_VOIS_PTW_USER_NAME
  }


  VVS_VOIS_ON_SITE_CHANGE(event: Event) {
    const VVS_VOIS_SELECTED_INDEX = (event.target as HTMLSelectElement).selectedIndex;
    const VVS_VOIS_SELECTED_OPTION = this.VVS_VOIS_SITE_LIST[VVS_VOIS_SELECTED_INDEX];
    this.router.navigate(['/admin/dashboard'], { replaceUrl: true });
    this._Datashare.VVS_VOIS_SET_SITE_ID(VVS_VOIS_SELECTED_OPTION.KEY);
  }







  logoutSec() {
    this.authService.VVS_VOIS_LOGOUT();
    history.pushState(null, "null", window.location.href)
   this.router.navigate(['']);
  }

}
